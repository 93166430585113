<template>
  <ProjectLayout
    :displayedCard="'edit-project'"
    v-if="hasAllDataLoaded"
  >
    <b-card
      title="Detalle de proyecto"
      class="user-create-card"
      align="left"
    >
      <ProjectForm
        ref="projectForm"
        :form_prop.sync="project"
        @onNewEmployee="onNewEmployee"
        @onNewHardware="onNewHardware"
      />
      <b-row class="footer">
        <b-col>
          <b-button v-if="adminPermission" variant="secondary" @click="onRemove" class="d-flex">
            <b-icon-trash></b-icon-trash>
          </b-button>
        </b-col>
        <b-col class="footer-buttons">
          <b-button variant="primary" @click="onSave(true)">
            Guardar cambios
          </b-button>
        </b-col>
      </b-row>
      <RemoveConfirmationModal
        id="confirmationModalId"
        @onConfirmRemoveConfirmation="onConfirmedRemove"
        @onCancelRemoveConfirmation="onCancelRemove"
      />
    </b-card>
  </ProjectLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ProjectLayout from '@/views/project/layout/ProjectLayout.vue'
import ProjectForm from '@/components/Business/Project/Form/ProjectForm.vue'
import RemoveConfirmationModal from '@/components/Modal/RemoveConfirmationModal'
import { BIconTrash } from 'bootstrap-vue'
import Profiles from '@/utils/getProfiles'

export default {
  name: 'EditProject',
  components: {
    ProjectLayout,
    ProjectForm,
    RemoveConfirmationModal,
    BIconTrash
  },
  created () {
    if (this.$store.state.currentProfile === Profiles.PROFILE_BUSINESS) {
      this.getAllEmployees()
      this.getOneById(this.$route.params.projectId)
      this.getAllHardware()
      this.getAllVenue()
      this.getAllTechnicianBooking(this.$route.params.projectId)
      this.getAllHardwareBooking(this.$route.params.projectId)
      this.listExternalTechnician(this.$route.params.projectId)
      this.listExternalHardware(this.$route.params.projectId)
    } else {
      this.getAllEmployeesTechnician(this.$route.params.projectId)
      this.getOneByIdTechnician(this.$route.params.projectId)
      this.getAllTechnicianHardware(this.$route.params.projectId)
      this.listExternalTechnician(this.$route.params.projectId)
      this.listExternalHardware(this.$route.params.projectId)
      this.getAllVenue()
    }
  },
  computed: {
    ...mapGetters({ project: 'project/current' }),
    ...mapGetters({ venues: 'venues/all' }),
    hasAllDataLoaded () {
      if (this.project != null) {
        if (this.project.venue != null) {
          if (this.venues.length > 0) {
            return true
          }
          return false
        }
        if (this.project.venueExtraName != null) {
          return true
        }
        return false
      }
      return false
    },
    adminPermission () {
      return this.$store.state.currentProfile === Profiles.PROFILE_BUSINESS ||
        this.project.managers.filter(manager => manager.id === this.$store.state.loggedUser.technician.id).length > 0
    }
  },
  methods: {
    ...mapActions('project', ['getOneById', 'getOneByIdTechnician', 'listExternalTechnician', 'listExternalHardware']),
    ...mapActions('employee', {
      getAllEmployees: 'getAll',
      getAllEmployeesTechnician: 'getAllTechnician'
    }),
    ...mapActions('hardware', {
      getAllHardware: 'getAll',
      getAllTechnicianHardware: 'getAllTechnician'
    }),
    ...mapActions('venues', { getAllVenue: 'getAll' }),
    ...mapActions('technicianBooking', {
      getAllTechnicianBooking: 'getAll',
      getTechnicianBookingById: 'getOneById'
    }),
    ...mapActions('hardwareBooking', {
      getAllHardwareBooking: 'getAll',
      getHardwareBookingById: 'getOneById'
    }),
    async onSave () {
      const isFormValid = await this.$refs.projectForm.$refs.projectForm.validate()
      if (!isFormValid) {
        return
      }
      this.lockScreen()
      const projectToUpdate = { ...this.project }
      const venueExists = this.venues.some(venue => venue.name === this.project.venueExtraName)
      if (venueExists) {
        projectToUpdate.venue = projectToUpdate.venue.id
        projectToUpdate.venueExtraName = null
      } else {
        if (!projectToUpdate.venue) {
          projectToUpdate.venue = null
        } else {
          projectToUpdate.venue = projectToUpdate.venue.id
        }
      }
      if (projectToUpdate.venueExtraName === '') {
        projectToUpdate.venueExtraName = null
      }
      this.$store.dispatch('project/update', projectToUpdate)
        .then(() => {
          this.unlockScreen()
          this.$store.dispatch('showConfirmationModal')
        })
    },
    onRemove () {
      this.$bvModal.show('confirmationModalId')
    },
    onCancelRemove () {
      this.$bvModal.hide('confirmationModalId')
    },
    onConfirmedRemove () {
      this.lockScreen()
      this.$store.dispatch('project/delete', this.project.id)
        .then(() => {
          this.onCancelRemove()
          this.unlockScreen()
          this.$store.dispatch('showConfirmationModal')
          this.$router.push({ name: 'project-list' })
        })
    },
    onNewEmployee (id) {
      this.$bvModal.hide(id)
      this.onSave()
      this.$router.push({ name: 'employee-create' })
    },
    onNewHardware (id) {
      this.$bvModal.hide(id)
      this.onSave()
      this.$router.push({ name: 'hardware-create' })
    }
  }
}
</script>

<style lang="less">
@import 'editProject.less';
</style>
